import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UpGestao } from './app.global';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
declare const navigator;

@Component({
  selector: 'app-root',
  template: `<div class="contentChart" *ngIf="up.loading"> 
  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
  </div><p-toast position="top-left"></p-toast>
<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  public up = UpGestao;
  constructor(public updates: SwUpdate) {
  }

  ngOnInit() {
    if (this.updates.isEnabled) {
      interval(60000).subscribe(() => {
        this.updates.checkForUpdate();
      });
      this.updates.available.subscribe(() => {
        if (confirm("Uma nova atualização do Up Gestão está disponivel, deseja atualizar agora?")) {
          this.updates.activateUpdate().then(() => document.location.reload())
        }
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.up = UpGestao);
    if (navigator.standalone == true) {
      this.toggleFullScreen();
    }
  }

  toggleFullScreen() {
    var doc: any = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }
}
