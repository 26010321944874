import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse
} from "@angular/common/http";
import { Observable, throwError, timer } from "rxjs";
import { map, catchError, switchMap, tap, filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { UpGestao } from '../app.global';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService, private router: Router, private local: LocalStorageService) {
    }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = this.addToken(request);
        return timer(0).pipe(
            switchMap(() =>
                next.handle(request).pipe(
                    map((event: HttpEvent<any>) => event),
                    catchError((error: HttpErrorResponse) => {
                        const data = {
                            status: error.status,
                            message: error
                        };
                        console.log(data)
                        if(error.status == 0){
                            this.messageService.add({ severity: 'error', summary: 'Ops...', detail: 'Sem conexão com a internet, impossivel realizar essa operação!' });
                        }

                        UpGestao.loading = false;
                        // this.messageService.add({ severity: 'error', summary: 'Ops...', detail: error.message });
                        return throwError(error);
                    })
                )
            )
        );
    }

    private addToken(request: HttpRequest<any>): HttpRequest<any> {
        let session: any = this.local.retrieve(environment.session)
        if (session) {
            request = request.clone({
                headers: request.headers.set("Authorization", `Bearer ${session.token}`)
            });
        }
        return request;
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
