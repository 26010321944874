import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UpGestao, getPesquisa } from "../app.global";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { LocalStorageService, LocalStorage } from "ngx-webstorage";
import { find } from 'rxjs/operators';


@Injectable({
  providedIn: "root",
})
export class ApiService {
  @LocalStorage(environment.session) session;

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private storage: LocalStorageService
  ) {}

  checkStatus() {
    return new Promise((resolve, reject) => {
      if (this.session) {
        resolve();
      } else {
        reject();
        this.messageService.add({
          severity: "error",
          summary: "Ops...",
          detail: "Sua sessão foi finalizada, efetue acesso novamente!",
        });
        this.router.navigate(["/auth/login"]);
      }
    });
  }

  getImage(mid: string, product?: boolean) {
    if (!mid) {
      return product
        ? "/assets/images/produto.png"
        : "/assets/images/avatar.png";
    }
    return "https://scripts.virtux.com.br/imageCache.php?id=".concat(mid);
  }

  async salvarPedido(data) {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.api.concat(usr).concat("/erp/vix-fin/mobile"), data)
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  login(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.api.concat("1006/sistema/auth/"), data)
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              this.storage.store(environment.session, response.records);
              setTimeout(() => {
                resolve(response.records);
              }, 100);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  async cliente(idCliente: any): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.api
            .concat(usr)
            .concat("/erp/vix-cad/" + idCliente + "/?q=(cad_status:A)")
        )
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }
  async getParam(assunto, filter?: any): Promise<any> {
    await this.checkStatus();
    return new Promise((resolve, reject) => {
      const usr = this.session.user.emp_ident_emp;
      this.http
        .get(environment.api.concat(usr).concat("/erp/vix-par/?" + filter))
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              let value = response.records;
              resolve(value[assunto]);
            } else {
              UpGestao.loading = false;
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  async findOne(entity: string, idCliente, extra?: string): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.api
            .concat(usr)
            .concat("/erp/" + entity + "/" + idCliente)
            .concat(extra ? extra : "")
        )
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  async find(entity: string, filter, free?: boolean): Promise<any> {
    await this.checkStatus();
    let objPesquisa = filter;
    const usr = this.session.user.emp_ident_emp;
    let queryString = getPesquisa(objPesquisa, "limit=50&offset=0");
    const toConcat = free
      ? "/erp/" + entity
      : "/erp/" + entity + "/listar-fast/";
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api.concat(usr).concat(toConcat).concat(queryString))
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }


  async calcularTributacao(data): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    const url = environment.api
      .concat(usr)
      .concat("/erp/tributacao");
    return new Promise((resolve, reject) => {
      this.http.post(url, data).subscribe(
        (response: any) => {
          if (response.status == 200) {
            resolve(response.records);
          } else {
            UpGestao.loading = false;
            this.messageService.add({
              severity: "error",
              summary: response.records.title || "Ops...",
              detail: response.records.msg,
            });
            reject(response.records);
          }
        },
        (err) => reject(err)
      );
    });
  }

  async ratearDescontoGeral(data): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    const url = environment.api
      .concat(usr)
      .concat("/erp/vix-fin/ratear-valores-atributo/?op=orcamento");
    return new Promise((resolve, reject) => {
      this.http.post(url, data).subscribe(
        (response: any) => {
          if (response.status == 200) {
            resolve(response.records);
          } else {
            UpGestao.loading = false;
            this.messageService.add({
              severity: "error",
              summary: response.records.title || "Ops...",
              detail: response.records.msg,
            });
            reject(response.records);
          }
        },
        (err) => reject(err)
      );
    });
  }

  async getLote(proCodPro: string ): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.api
            .concat(usr)
            .concat("/erp/vix-pro/lote/?q=(pro_cod_pro:" + proCodPro)
            .concat(",pro_tip_especifico:2)")
        )
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  async getSugestoes(sugestoes: any) {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api.concat(usr).concat("/erp/vix-fin/carregar-sugestoes/" + sugestoes))
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              resolve(response.records);
            } else {
              UpGestao.loading = false;
              this.messageService.add({
                severity: "error",
                summary: response.records.title || "Ops...",
                detail: response.records.msg,
              });
              reject(response.records);
            }
          },
          (err) => reject(err)
        );
    });
  }

  async calcularTributacaoSugestao(data): Promise<any> {
    await this.checkStatus();
    const usr = this.session.user.emp_ident_emp;
    const url = environment.api
      .concat(usr)
      .concat("/erp/vix-fin/calcular-imposto-sugestoes/");
    return new Promise((resolve, reject) => {
      this.http.post(url, data).subscribe(
        (response: any) => {
          if (response.status == 200) {
            resolve(response.records);
          } else {
            UpGestao.loading = false;
            this.messageService.add({
              severity: "error",
              summary: response.records.title || "Ops...",
              detail: response.records.msg,
            });
            reject(response.records);
          }
        },
        (err) => reject(err)
      );
    });
  }

}
